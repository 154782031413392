@import "../../styles/elements";

// define rotation
@mixin rotate($duration: 2s) {
    animation: spin $duration linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

body {
    user-select: none;
}

.match-log {
    .main-content {
        overflow-x: visible;
    }

    .accordion-wrap {
        margin-top: 15px;
        background: $color-white;
        border-radius: 10px;

        >* {
            background: inherit;
        }

        .ant-collapse-content-box {
            padding-top: 0;
        }

        .accordion-content-wrap {
            strong {
                font-weight: 600;
                display: block;
            }

            p {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .recording-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 33px;
    }

    .event-log-wrapper {
        margin-top: 30px;
        padding: 20px 0;
        position: relative;
        border-top: 1px solid transparent;

        &::before {
            content: "";
            position: absolute;
            top: -1px;
            left: 50%;
            width: 100vw;
            z-index: 999;
            height: 1px;
            transform: translateX(-50%);
            background: $color-ligth-grey;
        }
    }

    .content-box-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .content-shadow-box {
            >*:last-child {
                margin-bottom: 0 !important;
            }
        }

        .content-box-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;

            .content-time {
                font-size: 16px;
                font-weight: 600;
                line-height: 1.25;
                font-family: $primary-font-family;
                width: 50px;
            }

            .content-process-txt {
                flex: 1;
                display: inline-flex;
                gap: 5px;
                justify-content: center;

                >svg {
                    @include rotate(1.5s);
                }
            }

            .content-icon {
                width: 50px;
                display: flex;
                justify-content: flex-end;

                button {
                    padding: 0;
                    height: auto;
                    background: $color-white !important;
                }
            }
        }
    }
}

.team-score-wrap {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Quicksand';
    line-height: 1.25;
    color: $color-light-black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    span {
        font-size: 30px;
        font-weight: 700;
    }
}

.loading-score-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;

    >svg {
        @include rotate(1.5s);
    }
}