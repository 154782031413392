@import "../../styles/elements";

.timer-wrapper {
    background-color: $color-white;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;

    .timer-text {
        margin-bottom: 0px;
        font-size: 30px;
        color: $color-light-black;
    }

    .timer-action-wrap {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 15px;

        .ant-btn {
            height: auto;
            padding: 0 !important;
            border-radius: 20px;
        }

        .wrap-svg-disabled {

            svg,
            svg path {
                fill: $color-grey;
            }
        }
    }
}