.footer {
  background-color: transparent !important;
  z-index: 1;
}
.footer.floating-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
}
.footer.floating-footer.sticky {
  border-top: 1px solid #80B4A6;
  background-color: #ffffff !important;
}
.footer.floating-footer .ant-btn-lg {
  height: 45px;
  line-height: 1.25;
}
.footer .main-content {
  padding: 20px;
}

