$color-white: #ffffff;
$color-green: #076D52;
$color-green-bg: #076D5214;
$color-light-green: #80B4A6;
$color-silver-sand: #BABBBC;
$color-sky-blue: #20AAE4;
$color-light-black: #363636;
$color-grey: #949494;
$color-ligth-grey: rgba(148, 148, 148, 0.3);
$color-min-ligth-grey: rgba(148, 148, 148, 0.1);

$color-gradient-green: linear-gradient(180deg, #076D52 -13.15%, #FFFFFF 64.32%);


// color
$primary-color: $color-green;
$header-bg-color: $color-white;
$body-bg-color: $color-green-bg;
$primary-button-color: $color-green;


// font
$primary-font-family: 'Quicksand';
$font-size-title: 18px;
$font-size-title-md: 25px;