@import "./elements";

body {
  font-size: 15px;
  font-weight: 400;
  background: linear-gradient(to bottom, $color-green-bg 0%, $color-white 100%) no-repeat;
  font-family: $primary-font-family;
}

.p-1 {
  padding: 4px
}

.p-2 {
  padding: 8px
}

.p-4 {
  padding: 16px
}

.p-8 {
  padding: 32px
}

.p-12 {
  padding: 48px
}

.p-16 {
  padding: 64px
}

.mb-1 {
  margin-bottom: 4px
}

.mb-2 {
  margin-bottom: 8px
}

.mb-4 {
  margin-bottom: 16px
}

.mb-8 {
  margin-bottom: 32px
}

.mb-16 {
  margin-bottom: 64px
}

.mt-1 {
  margin-top: 4px
}

.mt-2 {
  margin-top: 8px
}

.mt-4 {
  margin-top: 16px
}

.mt-8 {
  margin-top: 32px
}

.mt-16 {
  margin-top: 64px
}

.mlp-25 {
  margin-left: 25%
}

.title-text {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-title;
}

.title-text-md {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-title-md;
}

.ant-btn-primary {
  background: $primary-button-color;
  border-radius: 0px 4px 4px 0px;
}

.ant-btn-lg {
  height: 55px;
}

.ant-btn-primary:not([disabled]):hover,
.ant-btn-primary:not([disabled]):focus {
  border-color: $primary-button-color;
  background: $primary-button-color;
}

.ant-btn-link {
  color: $primary-color;
}

.ant-input,
.ant-input-number-input,
.ant-select-lg .ant-select-selector {
  height: 50px !important;
  padding: 15px 20px !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: $primary-font-family !important;
  color: $color-light-black !important;
}

.ant-input-outlined:hover,
.ant-input-outlined:focus-within,
.ant-input-outlined:focus-visible,
.ant-input-outlined:focus,
.ant-input-number-outlined:hover,
.ant-input-number-outlined:focus-within,
.ant-input-number-outlined:focus-visible,
.ant-input-number-outlined:focus,
.ant-select-outlined:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-outlined:not(.ant-select-disabled):focus-within .ant-select-selector,
.ant-select-outlined:not(.ant-select-disabled):focus-visible .ant-select-selector,
.ant-select-outlined:not(.ant-select-disabled):focus .ant-select-selector {
  border-color: $primary-button-color !important;
  box-shadow: unset;
  border-radius: 10px;
}

.ant-input-number.ant-input-number-outlined {
  width: 100%;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 10px;
}

.ant-select-outlined.ant-select-disabled .ant-select-selector,
.ant-select-outlined.ant-select-disabled:hover .ant-select-selector,
.ant-select-outlined.ant-select-disabled:focus-within .ant-select-selector,
.ant-select-outlined.ant-select-disabled:focus-visible .ant-select-selector,
.ant-select-outlined.ant-select-disabled:focus .ant-select-selector {
  border-color: $color-ligth-grey !important;
  background: $color-ligth-grey !important;
  color: $color-grey !important;
}

.ant-input-affix-wrapper.ant-input-password {
  padding: 0 !important;

  .ant-input-suffix {
    width: 30px;
  }
}

.ant-select-outlined.ant-select-disabled .ant-select-arrow {
  svg path {
    fill: $color-grey !important;
    ;
  }
}

.ant-avatar {
  background-color: #ccc0;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-ctn {
  box-shadow: 0 0 12px 3px $color-min-ligth-grey;
}

.primary-color {
  color: $color-green;
}

h1,
h2,
h3,
h4,
h5,
label,
strong {
  font-family: $primary-font-family;
  margin: 0;
}

p {
  font-size: 15px;
  font-weight: 400;
  font-family: $primary-font-family;
  line-height: 1.25 !important;
  margin: 0;
}

label {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 1.25;
  color: $color-light-black !important;
}

::placeholder,
::-ms-input-placeholder {
  color: $color-grey;
}

input {
  border-color: $color-ligth-grey;
}

a {
  color: $primary-button-color !important;
  transition: all 0.2s;
  text-decoration: underline;
}

a:hover {
  color: $color-light-green !important;
}

.primary-btn-style:not([disabled]),
.primary-btn-style:not([disabled]):hover {
  background-color: $primary-button-color !important;
  border-color: $primary-button-color !important;
}

.ant-btn-primary:not([disabled]):not(.ant-btn-disabled):hover {
  background-color: $primary-button-color !important;
}

.content-shadow-box {
  background: #FFFFFF;
  padding: 15px;
  border-radius: 10px;

  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title-style {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 15px;
}

.ant-form>.ant-form-item {
  margin: 5px 0 10px;
  display: block;
}

.ant-collapse-header {
  padding: 14px 15px !important;
}

.ant-collapse-header-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  font-family: $primary-font-family;
}

.confirmation-modal-wrap {
  .ant-modal-content {
    padding: 15px;
  }

  .ant-modal-title {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    margin-bottom: 10px;
    color: $primary-color;
    font-family: $primary-font-family;
  }

  p {
    color: $color-light-black;
  }

  .ant-modal-footer {
    flex-direction: row-reverse;
    display: flex;
    gap: 10px;
    margin-top: 20px;

    button {
      border-radius: 30px;
      margin: 0 !important;
      font-size: 16px;
      line-height: 1.25;
      font-weight: 600;
      font-family: $primary-font-family;
      height: auto;
      padding: 11.5px 20px;

      &:hover {
        opacity: 0.8;
      }
    }

    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }

  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    color: $primary-color;
    border-color: $primary-color;
  }

  svg {
    color: $color-light-green;
  }
}