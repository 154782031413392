.begin-game {

    .main-content {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .content-wrap {
        display: flex;
        flex-direction: column;
    }

    .logo-wrap {
        .main-logo {
            display: block;
            max-width: 85px;
            margin: 0 auto 20px;
        }
    }

    .content-shadow-box {
        margin-bottom: 20px;

        span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            margin-bottom: 25px;
            width: fit-content;

            a {
                font-size: 16px;
                font-weight: 600;
                line-height: 1.25;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                img {
                    opacity: 0.5;
                }
            }
        }
    }

    .ant-btn {
        height: 45px;
        line-height: 1.25;
    }
}