.match-setup {
    .content-wrap {
        display: flex;
        flex-direction: column;
    }

    label::before {
        display: none !important;
    }

    .ant-select-lg {
        height: 50px;
    }

    form.ant-form {
        display: flex;
        flex-direction: column;
    }

    .ant-form>.ant-form-item:last-child {
        margin-bottom: 0;
    }
}