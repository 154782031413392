@import "../../../styles/elements";

.login-wrap {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $body-bg-color;
    padding: 15px 20px;

    .logo-wrap {
        .main-logo {
            display: block;
            max-width: 150px;
            margin: 0 auto 20px;
        }
    }
}

.container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    display: flow-root;
}

.login-info {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.login-form-box {

    .form {
        margin: 0 auto;
    }

    .form-title {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 768px) {
    .login-info {
        max-width: 55vw;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        overflow: hidden;
    }
}

@media only screen and (max-width: 400px) {

    .bg-absolute {
        width: 100%;
    }
}