@import "../../../styles/_elements";

.footer {
  background-color: transparent !important;
  z-index: 1;

  &.floating-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;

    &.sticky {
      border-top: 1px solid $color-light-green;
      background-color: $color-white !important;
    }

    .ant-btn-lg {
      height: 45px;
      line-height: 1.25;
    }
  }

  .main-content {
    padding: 20px;
  }
}