.page-wrap.review-score {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .main-content {
        width: 100%;

        p {
            color: #212529;
        }
    }

    label::before {
        display: none !important;
    }

    form.ant-form {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }

    .ant-form>.ant-form-item:last-child {
        margin-bottom: 0;
    }

    .ant-input-number-handler-wrap {
        display: none !important;
    }
}