@import "../../../styles/elements";

.header {
  z-index: 1;

  &.floating-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .header__inner {
    background-color: transparent;
    line-height: normal;
    padding: 15px 0 20px;
    height: auto;

    .header__title {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      color: inherit;
      text-transform: capitalize;
    }

    .backlink {
      .icon {
        font-size: 32px;
      }
    }

    .ant-row-flex {
      .ant-col {
        &:first-child {
          > :first-child {
            margin-right: 10px;
          }
        }
      }
    }

    .ant-btn {
      padding: 0;
      height: auto;
    }

    .image-wrap {
      font-size: 0;
    }
  }

  a {
    color: currentColor;
  }

  .icon {
    fill: currentColor;
  }

  .icon-menu {
    font-size: 48px;
    margin-right: 6px;
  }

  .icon-bell {
    font-size: 19px;
  }

  .icon-new-order {
    font-size: 16px;
  }

  .toolbar-button {
    background: transparent;
    border: none;

    &:hover {
      background: transparent;
      border: none;
    }
  }
}