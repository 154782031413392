.page-wrap.confirmation {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    main.main-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-content {
        width: 100%;

        .confirmation-content-wrap {
            padding: 0;
            flex: 0.3;
        }

        .content-wrap {
            flex: 1;
            justify-content: center;
            align-items: center;
            display: flex;
        }

        p {
            color: #212529;
        }

        .image-wrap {
            display: flex;
            justify-content: center;
            align-items: center;

            .icon-img {
                width: 230px;
                height: 230px;
                object-fit: contain;
                object-position: center;
                max-width: 100%;
            }
        }
    }

    .footer {
        padding: 0 !important;
    }
}