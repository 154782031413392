// RecordingButton.scss
.recording-button-holder {
    background-color: rgb(244, 247, 246);
    position: relative;
    padding: 8px;
    border-radius: 50%;
    // shadow
    box-shadow: 0px 0px 15px 4px rgba(128, 180, 166, 0.22);

    .CircularProgressbar {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    .spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: rotate 1s linear infinite;
    }


    .recording-button {
        position: relative;
        z-index: 99;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        width: 140px;
        height: 140px;
        border: 0px solid #FFFFFF;


        &.recording {
            background-color: #076D52;
            color: #fff;
        }

        &.hold-to-record {
            background-color: #fff;
            color: #076D52;
        }

        .icon {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }
}

.recording-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
        color: #076D52;
        font-size: 16px;
        margin-top: 10px;
        text-align: center;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}